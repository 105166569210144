import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ReactMapboxGl, { Marker } from '!react-mapbox-gl';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import MarkerImage from '../images/marker.png';
import { Link } from 'gatsby';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import SelectM from '@material-ui/core/Select';
import axios from 'axios';
import Loader from '../images/loader.svg';

const Map = ReactMapboxGl({
    accessToken: process.env.MAP_KEY,
});

const changeMapLanguage = (map) => {
    map.getStyle().layers.forEach((layer) => {
        if (layer.id.endsWith('-label')) {
            map.setLayoutProperty(layer.id, 'text-field', [
                'coalesce',
                ['get', 'name_ua'],
                ['get', 'name'],
            ]);
        }
    });
};

const mapProps = {
    zoom: [12],
    style: 'mapbox://styles/mapbox/streets-v11',
};

export default class ObjectPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbs: [
                {
                    title: 'Головна',
                    url: '/'
                }, {
                    title: 'Карта',
                    url: '/map'
                }, {
                    title: 'Об`єкт',
                }
            ],
            sort: [
                {
                    label: 'Всі',
                    value: 'all'
                }, {
                    label: 'Дійсні',
                    value: 'active'
                }, {
                    label: 'Недійсні',
                    value: 'disactive'
                }
            ],
            selectedSort: {
                label: 'Всі',
                value: 'all'
            },
            filters: [
                {
                    title: "Є потенційна недоплата",
                    value: "is_expected_2_plus_times_gr_than_payment",
                }, { 
                    title: "Некоректний код ЄДРПОУ орендаря",
                    value: "is_tennant_code_bug", 
                }, {
                    title: "Некоректний код ЄДРПОУ балансоутримувача",
                    value: "is_owner_code_bug",
                }, {
                    title: "Ризик заниженої оціночної вартості",
                    value: "is_less_avg_price_m2",
                }, {
                    title: "Є уточнення до ціни",
                    value: "is_price_footnotes",
                }, {
                    title: "Пільгова ставка для бізнесу",
                    value: "is_tennant_business_with_cheap_rent"
                }, {
                    title: "Оренда бізнесом під потреби ГО",
                    value: "is_tennant_ngo_business"
                }, {
                    title: "Тривале подовження у 2019-2020 рр.",
                    value: "is_big_longations_after_2019"
                }, {
                    title: "Багато ГО за однією адресою",
                    value: "is_more_than_one_ngo_agreement_per_object"
                }
            ],
            agreements: null,
            agreementsSorted: null,
            currentDate: null,
            isMobile: false,
            object: null,
        }

        this.updateSort = this.updateSort.bind(this);
    }

    updateSort(event) {
        const value = event.target.value;
        let defaultAgreements = this.state.agreements.slice();
        let sorted = null;

        if (value.value === 'active')
            sorted = defaultAgreements.filter(item => item.agreement_date_end.replace(/[\-%]/g, '') >= this.state.currentDate);

        if (value.value === 'disactive')
            sorted = defaultAgreements.filter(item => item.agreement_date_end.replace(/[\-%]/g, '') < this.state.currentDate);


        value && this.setState({
            selectedSort: value,
            agreementsSorted: sorted ? sorted : defaultAgreements
        });
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (!urlParams.get('id')) window.location.href = '404';

        this.setState({
            isMobile: window.innerWidth > 767 ? false : true
        });

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        this.setState({
            currentDate: yyyy + mm + dd
        });

        axios.get(`${process.env.API_ENDPOINT}/estate/objects/${urlParams.get('id')}`)
            .then((response) => {
                this.setState({
                    object: response.data[0],
                });
            })
            .catch((error) => {
                console.error(error);
            });

        axios.get(`${process.env.API_ENDPOINT}/estate/objects/${urlParams.get('id')}/agreements`)
            .then((response) => {
                this.setState({
                    agreements: response.data,
                    agreementsSorted: response.data,
                })
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        const object = this.state.object;

        return(
            <React.Fragment>
                {object && (
                    <Layout  
                    location={this.props.location} 
                    title={object.last_object_name}
                    >
                        <SEO
                            title={object.object_name}
                        />
                        <div className="view-object-post">
                            <div className="view-container">
                                <div className="view-object-post__header">
                                    <div className="view-object-post__header__content">
                                        <Breadcrumbs items={this.state.breadcrumbs}/>
                                        <h1 className="view-title view-title--h1 view-object-post__title">{object.object_name}</h1>
                                        <div className="view-object-post__header__data view-text">
                                            <p>
                                                <b>Балансоутримувач:</b> {object.owner_name}
                                            </p>
                                            <p>
                                                <b>Орган управління:</b> {object.regional_manager} 
                                            </p>
                                            <p>
                                                <b>Адреса:</b> {object.object_address}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="view-object-post__map">
                                        <Map 
                                            {...mapProps} 
                                            containerStyle={{
                                                height: this.state.isMobile ? '400px' : '550px',
                                                width: '100%'
                                            }}
                                            center={[object.lng, object.lat]}
                                            onStyleLoad={changeMapLanguage}
                                        >
                                            <Marker
                                                coordinates={[object.lng, object.lat]}>
                                                <img src={MarkerImage} alt="Marker" />
                                            </Marker>
                                        </Map>
                                    </div>
                                </div>
                            </div>
    
                            <section className="view-section view-section--blue view-object-post__agreements">
                                <div className="view-container">
    
    
                                    <div className="view-object-post__agreements__header view-form">
                                        <h2 className="view-object-post__agreements__title">
                                            Список договорів
                                        </h2>
    
                                        <FormControl className="view-form__group">
                                            <InputLabel id="region-label" className="MuiFormLabel-filled">Статус договору</InputLabel>
                                            <SelectM
                                                labelId="region-label"
                                                id="region"
                                                value={this.state.selectedSort}
                                                onChange={this.updateSort}
                                                renderValue={selected => selected.label}
                                                input={<Input />}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    }
                                                }}
                                            >
                                            {this.state.sort.map((area) => (
                                                <MenuItem className="view-form__select__item--default" key={area.value} value={area}>
                                                    <ListItemText primary={area.label} />
                                                </MenuItem>
                                            ))}
                                            </SelectM>
                                        </FormControl>
                                    </div>
    
    
                                    <div className="view-object-post__agreements__list">
                                        {this.state.agreementsSorted && this.state.agreementsSorted.map(item => (
                                            <div className="view-object-post__agreements__item">
                                                <div className="view-object-post__agreements__name">
                                                Договір № {item.agreement_no}
                                                </div>
                                                <div className="view-object-post__agreements__wrapper">
                                                    <div className="view-object-post__agreements__top">
                                                        <div className="view-object-post__agreements__prop">
                                                            <span>Площа:</span>
                                                            <b>{item.size_clear} м²</b>
                                                        </div>
                                                        <div className="view-object-post__agreements__prop">
                                                            <span>Статус:</span>
                                                            {(item.agreement_date_end.replace(/[\-%]/g, '')) < this.state.currentDate 
                                                                ? 
                                                                    <b style={{color: '#FF0000'}}>Недіючий</b>
                                                                :
                                                                    <b style={{color: '#378A10'}}>Діючий</b>
                                                            }
                                                        </div>
                                                        <div className="view-object-post__agreements__prop">
                                                            <span>Сума місячної оплати:</span>
                                                            <b>{item.price_monthly_clear} грн.</b>
                                                        </div>
                                                        <div className="view-object-post__agreements__prop">
                                                            <span>Орендар:</span>
                                                            <b>{item.tennant_name}</b>
                                                        </div>
                                                        <div className="view-object-post__agreements__prop">
                                                            <span>Діє з:</span>
                                                            <b>{item.agreement_date_start}</b>
                                                        </div>
                                                        <div className="view-object-post__agreements__prop">
                                                            <span>Діє по:</span>
                                                            <b>{item.agreement_date_end}</b>
                                                        </div>
                                                    </div>
                                                    <div className="view-object-post__agreements__bottom">
                                                        <div className="view-object-post__agreements__prop">
                                                            <span>Наявні ризики:</span>
                                                            <b>{this.state.filters.map(filter => item[filter.value] !== 0 && filter.title + '; ')}</b>
                                                        </div>
                                                    </div>
                                                    <Link to={`/agreement?id=${item.agreement_id}`} className="button button--link">Показати детальніше</Link>
                                                </div>
                                            </div>
                                        ))}
                                        {(this.state.agreementsSorted && !this.state.agreementsSorted.length) && (
                                            <p>Результати запиту відсутні</p>
                                        )}
                                        {!this.state.agreements && (
                                            <div className="view-loader">
                                                <Loader />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Layout>
                )}
            </React.Fragment>
        );
    }
}